import * as React from 'react';
import { Link } from 'gatsby';

const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();


    return(
        <footer className="bg-gray-900">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                <div className="px-5 py-2">
                    <Link to="/" className="text-base text-white hover:text-muse-yellow">
                    Home
                    </Link>
                </div>

                <div className="px-5 py-2">
                    <Link to="/team" className="text-base text-white hover:text-muse-yellow">
                    Our Team
                    </Link>
                </div>

                <div className="px-5 py-2">
                    <Link to="/services" className="text-base text-white hover:text-muse-yellow">
                    Services
                    </Link>
                </div>

                <div className="px-5 py-2">
                    <Link to="/patients" className="text-base text-white hover:text-muse-yellow">
                    Patients
                    </Link>
                </div>

                <div className="px-5 py-2">
                    <Link to="/clinic" className="text-base text-white hover:text-muse-yellow">
                    Clinic
                    </Link>
                </div>

                <div className="px-5 py-2">
                    <Link to="/contact-us" className="text-base text-white hover:text-muse-yellow">
                    Contact Us
                    </Link>
                </div>
                </nav>
                
                <div className="mt-8 flex justify-center">
                    <p className="text-gray-400">
                        Unit #3 - 9015 120th Street Delta, BC V4C 6R7 | 604-954-1223 | info@musedental.ca
                    </p>
                </div>
                <p className="mt-8 text-center text-base text-gray-400">
                &copy; {year} Muse Dental. All rights reserved.
                </p>
                <p className="text-base text-gray-400 xl:text-center">
                    Powered by <a className="hover:text-white" href="https://www.healthonedigital.com" target="_blank" rel="noreferrer noopenner">HealthOne Digital</a>
                </p>
        </div>
    </footer>
    )
}

export default Footer;