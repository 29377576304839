import * as React from 'react';
import Nav from './Nav.js';
import Footer from './Footer.js';

const Layout = ({children}) => {
    return(
        <div>
            <Nav/>
                {children}
            <Footer/>
        </div>
    )
}

export default Layout;