import * as React from "react";
import { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { Transition } from '@headlessui/react';
import MuseDentalLogo from '../images/Muse-Logo.png';

const Nav = () => {

  const [toggleMobileNav, setToggleMobileNav] = useState(false);

    return(
        <div className="relative pt-12 pb-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to=".">
                    <span className="sr-only">Muse Dental</span>
                    <img className="h-20" src={MuseDentalLogo} alt="Muse Dental Logo"/>
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <button type="button" onClick={() => setToggleMobileNav(true)} className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" id="main-menu" aria-haspopup="true">
                      <span className="sr-only">Open main menu</span>
                      
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:space-x-10">
                <Link to="/" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Home</Link>
      
                <Link to="/team" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Team</Link>
      
                <Link to="/services" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Services</Link>

               {/* <Link to="/patients" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Patients</Link> */}

                <Link to="/clinic" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Clinic</Link>
      
                <Link to="/contact-us" className="font-medium text-lg text-gray-800 hover:text-muse-yellow tracking-wide">Contact Us</Link>
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                  <button onClick={() => window.initFlexBook('https://flexbook.me/musedental')} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-sm text-white bg-muse-yellow hover:text-white hover:bg-muse-teal">
                    Book Appointment
                  </button>
                </span>
              </div>
            </nav>
          </div>
    
         {/* MOBILE MENU */}
         { toggleMobileNav ?

            <div className="absolute top-0 z-10 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div className="-mr-2">
                    <button type="button" onClick={() => setToggleMobileNav(false)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>

                <Transition show={toggleMobileNav} enter="duration-500 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95"> 
                <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                  <div className="px-2 pt-2 pb-3 relative z-100" role="none">
                
                    <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-muse-yellow tracking-wide">Home</Link>
      
                    <Link to="/team" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-muse-yellow tracking-wide">Team</Link>
          
                    <Link to="/services" className="block px-3 py-2 rounded-md text-base font-mediumtext-gray-800 hover:text-muse-yellow tracking-wide">Services</Link>

                   {/* <Link to="/patients" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-muse-yellow tracking-wide">Patients</Link> */}

                    <Link to="/clinic" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-muse-yellow tracking-wide">Clinic</Link>
          
                    <Link to="/contact-us" className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-muse-yellow tracking-wide">Contact Us</Link>

                  </div>
                  <div role="none">
                    <button onClick={() => window.initFlexBook('https://flexbook.me/musedental')} className="block w-full px-5 py-3 text-center font-medium text-white bg-muse-teal hover:bg-gray-100 hover:text-muse-teal" role="menuitem">
                      Book Appointment
                    </button>
                  </div>
                </div>
                </Transition>
              </div>
          </div> 
      : "" }
      </div>
    )

}

export default Nav;